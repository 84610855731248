(function () {
    'use strict';

    angular
        .module('lk.main')
        .directive('serviceBlock', serviceBlock);

    function serviceBlock() {
        var directive = {
            scope: {
                service: '=serviceBlock'
            },
            controller: 'serviceBlockController as vm',
            template: require("./template.html").default
        };
        return directive;
    }
})();
