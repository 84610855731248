(function () {
    'use strict';

    angular
        .module('core.feature')
        .provider('dataFeatureService', dataFeatureService);

    function dataFeatureService() {
        var apiUrl = {
            features: '/api/profile/features/'
        };

        var provider = {
            $get: $get
        };

        return provider;

        function $get($http, exception) {

            var getMethods = {
                getFeatures: getFeatures
            };

            return getMethods;

            function getFeatures() {
                return $http
                    .get(apiUrl.features)
                    .then(success)
                    .catch(error);

                function success(response) {
                    return response.data.features;
                }

                function error(message) {
                    exception.catcher('XHR Failed for getFeatures.')(message);
                }
            }
        }
    }
})();
