(function () {
    'use strict';

    angular
        .module('lk.main')
        .factory('serviceFilters', serviceFilters);

    function serviceFilters() {
        var filters = {
            'values': [
                {'name': 'Мобильный интернет', 'value': '415'},
                {'name': 'SMS-MMS сообщения', 'value': '416'},
                {'name': 'Возможноси при нуле', 'value': '417'},
                {'name': 'Информация и развлечения', 'value': '418'},
                {'name': 'Контроль расходов', 'value': '419'},
                {'name': 'Звонки', 'value': '420'}
            ],
            'field': 'tags',
            'type': 'checkbox'
        };
        return filters;
    }
})();
