(function () {
    'use strict';

    angular
        .module('lk.main')
        .directive('currentTariffBlock', currentTariffBlock);

    function currentTariffBlock() {
        var directive = {
            scope: {
                tariff: '=currentTariffBlock',
                changeTariff: '@changeTariff'
            },
            controller: 'currentTariffBlockController as ctbVm',
            template: require('./template.html').default
        };
        return directive;
    }
})();
