(function (angular) {
    'use strict';

    angular
        .module('lk.auth')
        .config(route)
        .run(['$templateCache', cacheIncludableTemplates]);

    function route($stateProvider) {
        var state = {
            name: 'auth',
            url: '/auth?next',
            abstract: true,
            views: {
                header: {
                    template: require("./templates/header.html").default
                },
                content: {
                    template: require("./templates/content.html").default
                },
                footer: {
                    controller: 'BaseController as baseVm',
                    template: require("./templates/footer.html").default
                }
            }
        };

        $stateProvider
            .state(state);
    }

    function cacheIncludableTemplates($templateCache) {
        $templateCache.put('auth/base/templates/errors.html', require("./templates/errors.html").default);
    }
})(window.angular);
