(function () {
    'use strict';

    angular
        .module('lk.auth')
        .controller('BaseController', controller);

    function controller(location_constant, contactPhone) {
        /* jshint validthis: true */
        var footerVm = this;

        footerVm.nowDate = new Date();
        footerVm.location = location_constant;
        footerVm.contactPhone = contactPhone;
    }
})();
