(function () {
    'use strict';

    angular
        .module('lk.main')
        .factory('tariffFilters', tariffFilters);

    function tariffFilters() {
        var filters = [{
            'values': [
                {'name': 'Безлимитный', 'value': '58'},
                {'name': 'Поминутный', 'value': '248'},
                {'name': 'Интернет', 'value': '59'}
            ],
            'field': 'tags',
            'type': 'radio'
        }];
        return filters;
    }
})();
