(function () {
    'use strict';

    angular
        .module('lk.main')
        .controller('BlockNumberController', controller);

    function controller($rootScope, BlockNumberModal, dataService, moment) {
        /*jshint validthis: true */
        var modal = this;

        modal.close = closeWindow;
        modal.send = send;
        modal.block = false;
        modal.error = false;
        modal.errorMsg = null;
        modal.today = moment().subtract(1, 'day');
        modal.date = moment();
        modal.time = moment();
        modal.endDate = moment().add(10, 'days');
        modal.endTime = moment();

        $rootScope.isModalActive = true;

        return modal;

        function send() {
            var date = moment(modal.date).format('YYYY-MM-DD'),
                endDate = moment(modal.endDate).format('YYYY-MM-DD'),
                time = moment(modal.time).format('THH:mm:ssZ'),
                endTime = moment(modal.endTime).format('THH:mm:ssZ');
            modal.block = true;
            return dataService
                .setNumberCreateBlockRequest(date + time, endDate + endTime)
                .then(success)
                .catch(error);

            function success() {
                modal.block = false;
                $rootScope.$broadcast('updateNumberBlockRequest');
                modal.close();
            }

            function error(response) {
                var msg = response.data ? response.data['non_field_errors'] : null;
                modal.errorMsg = msg && msg.length ? msg[0] : null;
                modal.block = false;
                modal.error = true;
                $rootScope.$broadcast('updateNumberBlockRequest');
            }
        }

        function closeWindow() {
            if (modal.block) {
                return false;
            }
            return BlockNumberModal
                .deactivate()
                .then(success);

            function success() {
                modal.block = false;
                $rootScope.isModalActive = false;
            }
        }
    }
})();
