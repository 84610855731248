(function () {
    'use strict';

    angular
        .module('core.router')
        .config(config);

    function config($urlRouterProvider) {
        $urlRouterProvider.otherwise(function ($injector, $location) {
            var $state = $injector.get('$state');
            var $rootScope = $injector.get('$rootScope');
            var shared = $injector.get('satellizer.shared');

            $rootScope.pageNotFoundUrl = $location.absUrl();

            if (shared.isAuthenticated()) {
                return $state.go('lk.404');
            } else {
                return $state.go('auth.login');
            }
        });
    }
})();
