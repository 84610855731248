(function () {
    'use strict';

    angular
        .module('lk.main')
        .factory('CallbackModal', CallbackModal);

    function CallbackModal(ngModal) {
        return ngModal({
            controller: 'CallbackController as modal',
            container: document.getElementById('popups'),
            template: require("./template.html").default
        });
    }
})();
