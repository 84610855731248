(function () {
    'use strict';

    angular
        .module('lk.main')
        .controller('FooterController', controller);

    function controller(location_constant, contactPhone, CallbackModal) {
        /* jshint validthis: true */
        var footerVm = this;

        footerVm.nowDate = new Date();
        footerVm.callback = CallbackModal.activate;
        footerVm.location = location_constant;
        footerVm.contactPhone = contactPhone;
    }
})();
