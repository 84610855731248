// Angular.js и внешние модули
import "angular";
import "angular-animate";
import "angular-messages";
import "angular-sanitize";
import "angular-strap/dist/angular-strap";
import "angular-strap/dist/angular-strap.tpl";
import "angular-ui-mask/dist/mask";
import "angular-ui-router";
import "checklist-model";
import "re-tree";
import "ng-device-detector/ng-device-detector";
import "satellizer/satellizer";

// Плагины JQuery
import "chosen-js/chosen.jquery";

// Vendors
import "./vendors/angular-locale_ru-ru";
import "./vendors/angular-moment/angular-moment";
import "./vendors/ng-modal";

// Импортируем все JS из ./app
const appRequire = require.context("./app", true, /\.js$/);
APP_REQUIRES_ORDERED.forEach(path => appRequire(path));

import "./index.less";
