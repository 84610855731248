(function () {
    'use strict';

    angular
        .module('core.authenticate')
        .provider('dataAuthService', dataAuthService);

    function dataAuthService() {
        var apiUrl = {
            login: '/api/auth/login/',
            changePassword: '/api/profile/',
            getPassword: '/api/auth/get_password/',
            recovery: '/api/auth/password_recovery/',
            confirmEmail: '/api/auth/email_confirmation/'
        };

        var provider = {
            $get: $get
        };

        return provider;

        function $get($http) {

            var getMethods = {
                login: login,
                changePassword: changePassword,
                getPassword: getPassword,
                recoveryCode: recoveryCode,
                confirmEmail: confirmEmail
            };
            return getMethods;

            function confirmEmail(code) {
                return $http
                    .post(apiUrl.confirmEmail, {token: code});
            }

            function login(data) {
                return $http
                    .post(apiUrl.login, data);
            }

            function changePassword(data) {
                return $http
                    .put(apiUrl.changePassword, data);
            }

            function getPassword(data) {
                return $http
                    .post(apiUrl.getPassword, data);
            }

            function recoveryCode(code) {
                return $http
                    .post(apiUrl.recovery, {token: code});
            }
        }
    }
})();
