(function () {
    'use strict';

    angular
        .module('lk.main')
        .controller('serviceBlockController', controller);

    function controller($scope, dataService,
                        ServiceConnectModal, ServiceDisconnectModal,
                        ServiceRemoveConnectModal, ServiceRemoveDisconnectModal) {
        /*jshint validthis: true */
        var vm = this;
        vm.service = $scope.service;

        vm.showConnectModal = ServiceConnectModal.activate;
        vm.showDisconnectModal = ServiceDisconnectModal.activate;
        vm.showRemoveConnectModal = ServiceRemoveConnectModal.activate;
        vm.showRemoveDisconnectModal = ServiceRemoveDisconnectModal.activate;
    }

})();
