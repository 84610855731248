(function () {
    'use strict';

    angular
        .module('lk.app')
        .controller('AppController', controller);

    function controller($rootScope, $scope, $state) {
        /*jshint validthis: true */
        var appVm = this;

        appVm.$state = $state;
        $scope.$rootScope = $rootScope;

        return appVm;
    }
})();
