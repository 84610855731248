(function () {
    'use strict';

    angular
        .module('core.authenticate')
        .config(config);

    function config($authProvider, $httpProvider) {
        $authProvider.tokenName = 'auth_token';
        $authProvider.loginUrl = '/api/auth/login/';
        $httpProvider.interceptors.push('changePasswordTokenInterceptor');
        $httpProvider.interceptors.push('expireTokenInterceptor');
    }
})();
