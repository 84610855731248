(function () {
    'use strict';

    angular
        .module('lk.main')
        .filter('unsafe', function($sce) { return $sce.trustAsHtml; })
        .controller('currentTariffBlockController', controller);

    function controller($scope, $timeout, moment, _, dataService, FeatureService) {
        /*jshint validthis: true */
        var ctbVm = this,
            triedToUpdateCounters = false;

        ctbVm.changeTariff = $scope.changeTariff;
        ctbVm.loadingTarif = false;
        ctbVm.loadingCounters = false;
        ctbVm.update = updateAtClick;

        if (!$scope.tariff) {
            autoUpdate();
        } else {
            ctbVm.tariff = $scope.tariff;
        }

        return ctbVm;

        function successTariffCurrent(tariff) {
            if (_.keys(tariff).length) {
                ctbVm.tariff = tariff;
            }
            ctbVm.loadingTarif = false;
        }

        function successCounters(counters) {
            var dateReducer = function (acc, val) {
                    var accDate = new Date(acc.on_date),
                        valDate = new Date(val.on_date);

                    if (accDate > valDate) {
                        return val;
                    }
                    return acc;
                },
                onDateThreshold = moment().subtract(1, 'hour'),
                oldestCounterDate = counters.length ? new Date(counters.reduce(dateReducer).on_date) : new Date();

            if (oldestCounterDate < onDateThreshold && !triedToUpdateCounters) {
                triedToUpdateCounters = true;
                dataService
                    .getCountersUpdateRequest()
                    .then(checkCountersUpdated);
            } else {
                ctbVm.counters = counters;
                ctbVm.updateAt = oldestCounterDate > onDateThreshold ? moment(oldestCounterDate).format('HH:mm') :
                    '<span style="color:red">Не удалось обновить счетчики</span>';
                ctbVm.loadingCounters = false;
            }
        }

        function checkCountersUpdated (result) {
            var token = result.token,
                accTime = 0,
                checkReady = function (callback) {
                    var processResult = function (result) {
                            if (!result.ready && accTime < 60) {
                                accTime += 5;
                                $timeout(callback, 5000);
                            } else {
                                dataService
                                    .getCountersRequest()
                                    .then(successCounters);
                            }
                        };

                    return processResult;
                },
                makeRequest = function () {
                    dataService
                        .getCountersUpdateRequest(token)
                        .then(checkReady(makeRequest));
                };

            $timeout(makeRequest, 5000);
        }

        function update (fullUpdate) {
            ctbVm.loadingTarif = true;
            dataService
                .getTariffCurrent()
                .then(successTariffCurrent);

            if (FeatureService.isFeatureAvailable('all__counters')) {
                ctbVm.loadingCounters = true;

                if (fullUpdate) {
                    dataService
                        .getCountersUpdateRequest()
                        .then(checkCountersUpdated);
                } else {
                    dataService
                        .getCountersRequest()
                        .then(successCounters);
                }
            }
        }

        function autoUpdate () {
            update();
        }

        function updateAtClick () {
            update(true);
        }
    }

})();
