(function (angular) {
    'use strict';

    angular
        .module('lk.auth')
        .config(route);

    function route($stateProvider) {
        var state = {
            name: 'auth.email_confirm',
            url: '/email_confirmation/{code}',
            template: require("./template.html").default,
            controller: 'ConfirmController as confirmVm'
        };

        $stateProvider
            .state(state);
    }
})(window.angular);
