(function() {
    'use strict';

    angular.module('lk.core', [
        /*
         * Angular modules
         */
        'ngAnimate',
        'ngSanitize',
        'ngMessages',

        /*
         * Our reusable cross app code modules
         */
        'core.sentry',
        'core.exception',
        'core.logger',
        'core.router',
        'core.authenticate',
        'core.feature',
        'core.windowLocation',

        /*
         * 3rd Party modules
         */
        'ng.deviceDetector',
        'angularMoment',
        'checklist-model',
        'ngModal',
        'ui.mask',
        'mgcrea.ngStrap'
    ]);
})();
