(function () {
    'use strict';

    angular
        .module('core.windowLocation')
        .factory('$windowLocation', windowLocation);

    // В отличие от $window.location, методы этого объекта можно мокать.
    function windowLocation($window) {
        return {
            replace: function (url) {
                $window.location.replace(url);
            },
            reload: function () {
                $window.location.reload();
            },
        };
    }
})();
