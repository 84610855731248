(function () {
    'use strict';

    angular
        .module('lk.main')
        .controller('ReportsController', controller);

    function controller($scope, $stateParams, reportsDataService, moment,
                        reportsFilters, ReportsModal, FeatureService) {
        /* jshint validthis: true */
        var reportsVm = this;

        var today = $stateParams.today || $scope.today ? moment($stateParams.today || $scope.today) : moment();

        reportsVm.filter = reportsFilters;
        reportsVm.selectDateRange = $stateParams.period || 'day';
        reportsVm.period = {};
        reportsVm.save = ReportsModal.activate;
        reportsVm.updateAll = updateAll;

        reportsVm.togglePanel = togglePanel;

        reportsVm.dateRange = {
            day: {
                from: moment(today),
                unit: false
            },
            week: {
                from: moment(today).subtract(7, 'days'),
                unit: false
            },
            twoWeek: {
                from: moment(today).subtract(14, 'days'),
                unit: false
            },
            month: {
                from: moment(today).subtract(1, 'month'),
                unit: false
            }
        };

        reportsVm.changeDateRange = function () {
            var range = reportsVm.dateRange[reportsVm.selectDateRange];
            if (range) {
                reportsVm.methods.payment.period.set(range.from, range.unit);
                reportsVm.methods.history.period.set(range.from, range.unit);
                reportsVm.methods.expense.period.set(range.from, range.unit);
            } else if (reportsVm.selectDateRange === 'period') {
                reportsVm.methods.payment.period.set(reportsVm.period.from, reportsVm.period.unit);
                reportsVm.methods.history.period.set(reportsVm.period.from, reportsVm.period.unit);
                reportsVm.methods.expense.period.set(reportsVm.period.from, reportsVm.period.unit);
            }

            reportsVm.updateAll();
        };

        reportsVm.methods = {
            payment: {
                updateAt: moment(),
                update: paymentUpdate,
                next: {
                    get: paymentGetNext,
                    has: reportsDataService.payment.hasNext
                },
                order: {
                    toggle: paymentOrderToggle,
                    status: reportsDataService.payment.order.status
                },
                period: {
                    set: reportsDataService.payment.period.set
                }
            },
            history: {
                updateAt: moment(),
                update: historyUpdate,
                selectedFiltersUpdate: selectedFiltersUpdate,
                next: {
                    get: historyGetNext,
                    has: reportsDataService.history.hasNext
                },
                period: {
                    set: reportsDataService.history.period.set
                }
            },
            expense: {
                period: {
                    set: reportsDataService.expense.period.set
                }
            }
        };

        activate();

        return reportsVm;

        function activate() {
            reportsVm.changeDateRange();
        }

        function paymentUpdate() {
            if (!FeatureService.isFeatureAvailable('reports__payment')) {
                return false;
            }
            reportsVm.loadingPayment = true;
            return reportsDataService.payment.get()
                .then(paymentGet)
                .then(function () {
                    reportsVm.methods.payment.updateAt = moment();
                })
                .catch(defaultError);
        }

        function paymentGet(payment) {
            reportsVm.payment = payment;
            reportsVm.loadingPayment = false;
        }

        function paymentGetNext() {
            reportsVm.loadingPayment = true;
            return reportsDataService
                .payment
                .next()
                .then(success)
                .catch(defaultError);

            function success(data) {
                reportsVm.payment = reportsVm.payment.concat(data);
                reportsVm.loadingPayment = false;
            }
        }

        function paymentOrderToggle(field) {
            reportsDataService.payment.order.toggle(field);
            return paymentUpdate();
        }

        function historyUpdate() {
            if (!FeatureService.isFeatureAvailable('reports__history')) {
                return false;
            }
            reportsVm.loadingHistory = true;
            return reportsDataService.history.get()
                .then(historyGet)
                .then(function () {
                    reportsVm.methods.history.updateAt = moment();
                })
                .catch(defaultError);
        }

        function historyGet(histories) {
            reportsVm.histories = histories;
            reportsVm.loadingHistory = false;
        }

        function historyGetNext() {
            reportsVm.loadingHistory = true;
            return reportsDataService
                .history
                .next()
                .then(success)
                .catch(defaultError);

            function success(data) {
                reportsVm.histories = reportsVm.histories.concat(data);
                reportsVm.loadingHistory = false;
            }
        }

        function expenseUpdate() {
            if (!FeatureService.isFeatureAvailable('reports__expense')) {
                return false;
            }
            reportsVm.loadingExpense = true;
            return reportsDataService.expense.get()
                .then(expenseGet)
                .catch(defaultError);
        }

        function expenseGet(expenses) {
            reportsVm.expenses = expenses;
            reportsVm.loadingExpense = false;
        }

        function selectedFiltersUpdate() {
            reportsDataService.history.detailTypes.set(reportsVm.selected);
            return reportsVm.methods.history.update();
        }

        function defaultError() {
            reportsVm.loadingPayment = false;
            reportsVm.loadingHistory = false;
        }

        function togglePanel() {
            reportsVm.panel = reportsVm.panel ? false : true;
        }

        function updateAll() {
            historyUpdate();
            paymentUpdate();
            expenseUpdate();
        }
    }
})();
