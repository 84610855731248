(function () {
    'use strict';

    angular
        .module('lk.main')
        .controller('ProfileController', controller);

    function controller(dataService, auth, _) {
        /* jshint validthis: true */
        var profileVm = this;
        profileVm.edit = false;
        profileVm.send = send;
        profileVm.form = {};
        profileVm.formValues = {};
        profileVm.cancel = cancel;

        activate();

        return profileVm;

        function activate() {
            updateProfile();
        }

        function send(data) {
            if (profileVm.block) {
                return false;
            }

            profileVm.block = true;
            if (!_.get(data, 'new_password')) {
                delete data.new_password; //jshint ignore:line
            }

            return dataService
                .setProfile(data)
                .then(success);

            function success(response) {
                profileVm.block = false;
                profileVm.formValues.new_password = ''; // jshint ignore:line
                if (_.get(response, 'status') !== 200) {
                    if (_.get(response, 'data.email')) {
                        profileVm.form.$valid = false;
                        profileVm.form.email.$error = {
                            email: true
                        };
                    }
                    return false;
                }

                auth.setToken(response, true);
                profileVm.edit = false;
                return updateProfile();
            }
        }

        function updateProfile() {
            profileVm.block = true;
            return dataService
                .getProfile()
                .then(success);

            function success(profile) {
                profileVm.block = false;
                if (profile) {
                    profileVm.profile = profile;
                    profileVm.formValues.email = profile.email || '';
                } else {
                    profileVm.edit = true;
                }
            }
        }

        function cancel() {
            profileVm.formValues.email = profileVm.profile.email;
            profileVm.formValues.new_password = ''; // jshint ignore:line
            profileVm.form.$setPristine();
            profileVm.edit = false;

        }
    }
})();
