(function () {
    'use strict';

    angular
        .module('lk.auth')
        .controller('LoginController', controller);

    function controller($scope, auth, $location, $stateParams, $state, authVar, _) {
        /* jshint validthis: true */
        var loginVm = this;

        loginVm.form = {};
        loginVm.formValues = {};
        loginVm.send = send;

        loginVm.ad = {
            watch: [
                {
                    title: 'Детализация',
                    text: 'Возможность заказать детализацию, которая будет отображена в личном кабинете',
                    image: require('/images/detail-pic.jpg'),
                    active: true
                },
                {
                    title: 'История платежей',
                    text: 'Визуальное представление истории ваших платежей',
                    image: require('/images/history.jpg'),
                },
                {
                    title: 'Сохранение отчетов',
                    text: 'Сохраните отчет или отправте его на email',
                    image: require('/images/save_report.jpg'),
                },
                {
                    title: 'Пополнение',
                    text: 'Вносите деньги на баланс с банковских карт',
                    image: ''
                }
            ],
            service: [
                {
                    title: 'Детализация',
                    text: 'Возможность заказать детализацию, которая будет отображена в личном кабинете',
                    image: require('/images/detail-pic.jpg'),
                    active: true
                },
                {
                    title: 'Сравнение счетов',
                    text: 'Визуальное сравнение ваших затрат, градация по временному периоду, а также услугам',
                    image: require('/images/tariff-pic-big.png')
                },
                {
                    title: 'Контроль',
                    text: 'Контролируйте расходы всех ваших номеров, а также номеров ваших детей',
                    image: require('/images/service-pic.jpg')
                }
            ],
            setActive: setActive,
            setNext: setNext,
            setPrevious: setPrevious
        };

        return loginVm;

        function setActive(current, items) {
            _.each(items, each);

            current.active = true;

            return items;

            function each(item) {
                item.active = false;
            }
        }

        function setNext(items) {
            var index = _.indexOf(items, _.find(items, 'active'));
            if (index >= (items.length - 1)) {
                index = 0;
            } else {
                index += 1;
            }
            items = setActive(items[index], items);
            return items;
        }

        function setPrevious(items) {
            var index = _.indexOf(items, _.find(items, 'active'));
            if (index <= 0) {
                index = items.length - 1;
            } else {
                index -= 1;
            }
            items = setActive(items[index], items);
            return items;
        }

        function send(data) {
            if (loginVm.block) {
                return false;
            }
            loginVm.block = true;
            auth
                .login(data)
                .then(success)
                .catch(error);
        }

        function success(permanent) {
            if (permanent) {
                var next = $stateParams.next ? $stateParams.next : authVar.nextUrl;
                return $location.path(next);
            } else {
                $state.go('auth.changepass');
            }
        }

        function error(response) {
            loginVm.passwordExpired = response.status === 403;
            loginVm.phoneOrPasswordInvalid = response.status === 401;
            loginVm.userUnActive = response.status === 441;
            loginVm.userDenyAccess = response.status === 442;

            loginVm.block = false;
            loginVm.formValues.password = '';

            if (response.status === 441 || response.status === 442) {
                loginVm.form.password.$setPristine();
                return true;
            }

            loginVm.form.password.$error = {
                password: true
            };
            loginVm.form.$valid = false;

            $scope.passwordWatch = $scope.$watchCollection(
                '[loginVm.formValues.password, loginVm.formValues.phone]',
                watch
            );

            function watch(oldArray, newArray) {
                if (!_.difference(oldArray, newArray).length) {
                    return false;
                }
                loginVm.form.password.$error = {
                    password: false
                };
                $scope.passwordWatch();
            }
        }
    }
})();
