(function () {
    'use strict';

    angular
        .module('lk.app')
        .directive('chosenMobile', chosenMobile);

    function chosenMobile(deviceDetector) {
        var directive = {
            scope: {
                value: '='
            },
            link: link,
            template: '<div class="select__wrap-block" ng-show="isMobile">\n    ' +
            '<span ng-bind="values[value]"></span>\n</div>'
        };
        return directive;

        function link(scope, elem) {
            var element = $(elem);
            var select = element.parent().find('select');

            scope.values = {
                day: 'За сегодня',
                week: 'За последнюю неделю',
                twoWeek: 'За последние 2 недели',
                month: 'За последний месяц',
                period: 'За период'
            };

            if (deviceDetector.os === 'ios' || deviceDetector.os === 'android') {
                scope.isMobile = true;
                element.on('click', onClick);
            }

            return false;

            function onClick(event) {
                event.preventDefault();
                open(select);
            }

            function open(elem) {
                if (document.createEvent) {
                    var e = document.createEvent('MouseEvents');
                    e.initMouseEvent('mousedown', true, true, window, 0, 0, 0,
                        0, 0, false, false, false, false, 0, null);
                    elem[0].dispatchEvent(e);
                } else if (element.fireEvent) {
                    elem[0].fireEvent('onmousedown');
                }
            }
        }
    }
})();
