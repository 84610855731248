(function () {
    'use strict';

    angular
        .module('core.sentry')
        .controller('SentryController', controller);

    function controller($scope) {
        /* jshint validthis: true */
        var sentryVm = this;

        $scope.throwError = function() {
            throw new Error("Ошибка, сгенерированная вручную");
        };

        return sentryVm;
    }
})();
