(function () {
    'use strict';

    angular
        .module('lk.main')
        .controller('TariffsController', controller);

    function controller($rootScope, dataService, tariffFilters, TariffHelper,
                        TariffConnectModal, TariffDisconnectModal, _) {
        /* jshint validthis: true */
        var tariffsVm = this;

        tariffsVm.filters = tariffFilters;
        tariffsVm.selected = TariffHelper.getSelectedSchema(tariffsVm.filters);
        tariffsVm.isValueContain = TariffHelper.isValueContain;
        tariffsVm.isCleanFilters = TariffHelper.isCleanFilters;
        tariffsVm.setUnCheckedValue = TariffHelper.setUnCheckedValue;

        tariffsVm.togglePanel = togglePanel;
        tariffsVm.showConnectModal = TariffConnectModal.activate;
        tariffsVm.showDisconnectModal = TariffDisconnectModal.activate;

        $rootScope.$on('updateTariffs', updateTariffs);

        activate();

        return tariffsVm;

        function activate() {
            updateTariffs();
        }

        function updateTariffs() {
            tariffsVm.loadingTariffs = true;
            return dataService
                .getTariffs()
                .then(successTariffs)
                .then(successRequest);

            function successTariffs(tariffs) {
                tariffsVm.tariffs = TariffHelper.getConvertedTagsTariffs(tariffs);
                return dataService.getTariffRequest();
            }

            function successRequest(request) {
                if (_.get(request, 'tarif')) {
                    tariffsVm.tariffs = _.map(tariffsVm.tariffs, map);
                }
                tariffsVm.loadingTariffs = false;
                return tariffsVm.tariffs;

                function map(tariff) {
                    if (tariff.url === request.tarif) {
                        tariff.expectedDate = request.expected_date; // jshint ignore:line
                        tariff.disableDisconnect = request.in_progress; // jshint ignore:line
                    } else {
                        tariff.disableConnect = true;
                    }
                    return tariff;
                }
            }
        }

        function togglePanel() {
            tariffsVm.panel = tariffsVm.panel ? false : true;
        }

    }
})();
