(function () {
    'use strict';

    angular
        .module('lk.main')
        .controller('CallbackController', controller);

    function controller($scope, $rootScope, CallbackModal, dataService, moment) {
        /*jshint validthis: true */
        var modal = this;

        modal.close = closeWindow;
        modal.send = send;
        modal.block = false;
        modal.error = false;
        modal.date = moment();
        modal.phone = $rootScope.phone ? $rootScope.phone.phone : '';

        $rootScope.isModalActive = true;

        return modal;

        function send() {
            modal.block = true;
            return dataService
                .setCallbackRequest(modal.phone)
                .then(success)
                .catch(error);

            function success() {
                modal.block = false;
                modal.close();
            }

            function error() {
                modal.block = false;
                modal.error = true;
            }
        }

        function closeWindow() {
            if (modal.block) {
                return false;
            }
            return CallbackModal
                .deactivate()
                .then(success);

            function success() {
                modal.block = false;
                $rootScope.isModalActive = false;
            }
        }
    }
})();
