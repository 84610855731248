(function () {
    'use strict';

    angular
        .module('core.authenticate')
        .factory('changePasswordTokenInterceptor', [
            'satellizer.shared',
            'authVar',
            changePasswordTokenInterceptor
        ])
        .factory('expireTokenInterceptor', [
            '$q',
            '$windowLocation',
            'satellizer.shared',
            'authVar',
            expireTokenInterceptor
        ]);

    function changePasswordTokenInterceptor(shared, authConfig) {
        return {
            request: function (httpConfig) {
                if (shared.isAuthenticated()) {
                    return httpConfig;
                }
                var token = localStorage.getItem(authConfig.changePasswordTokenName);
                if (token) {
                    token = 'Bearer ' + token;
                    httpConfig.headers.Authorization = token;
                }
                return httpConfig;
            }
        };
    }

    function expireTokenInterceptor($q, $windowLocation, shared, authConfig) {
        return {
            responseError: function (rejection) {
                if (rejection.status === 440) {
                    shared.removeToken();
                    localStorage.removeItem(authConfig.changePasswordTokenName);
                    $windowLocation.replace('/auth/relogin');
                    return $q.reject(rejection);
                }
                return $q.reject(rejection);
            }
        };
    }
})();
