(function (angular) {
    'use strict';

    angular
        .module('lk.main')
        .config(route);

    function route($stateProvider) {
        var state = {
            name: 'lk.profile',
            url: 'profile/',
            template: require("./profile.html").default,
            controller: 'ProfileController as profileVm',
            authenticate: true
        };

        $stateProvider
            .state(state);
    }
})(window.angular);
