(function () {
    'use strict';

    angular
        .module('lk.core')
        .provider('dataService', dataService);

    function dataService() {
        var apiUrl = {
            profile: '/api/profile/',
            profilePhone: '/api/profile/phone/',
            profileBalance: '/api/profile/balance/',
            tariffs: '/api/tariff/',
            tariffCurrent: '/api/tariff/current/',
            tariffChangeRequest: '/api/change_tariff_request/',
            services: '/api/service/',
            serviceConnected: '/api/service/connected/',
            contactEmailRequest: '/api/server_error_ticket/',
            serviceChangeRequest: '/api/change_service_request/',
            reportsPayment: '/api/report/payment/',
            reportsHistory: '/api/report/operation_history/',
            reportsExpense: '/api/report/expense/',
            reportsCreateRequest: '/api/report/create/',
            reportsStatus: '/api/report/status/',
            callbackRequest: '/api/reverse_call_ticket/',
            numberBlockRequest: '/api/number_block_request/',
            numberUnblockRequest: '/api/number_unblock_request/',
            counters: '/api/counters/',
            countersUpdate: '/api/counters/update/',
            promo: '/api/promo/random/'
        };

        var provider = {
            $get: /*@ngInject*/$get
        };

        return provider;

        function $get($http, $q, $interval, exception, _, moment) {

            var getMethods = {
                getProfile: getProfile,
                setProfile: setProfile,
                getPhone: getPhone,
                getBalance: getBalance,
                getTariffs: getTariffs,
                getTariffCurrent: getTariffCurrent,
                getTariffRequest: getTariffRequest,
                setTariffCreateRequest: setTariffCreateRequest,
                setTariffDeleteRequest: setTariffDeleteRequest,
                getServices: getServices,
                getServiceConnected: getServiceConnected,
                getServiceRequest: getServiceRequest,
                setServiceCreateRequest: setServiceCreateRequest,
                setServiceDeleteRequest: setServiceDeleteRequest,
                setCallbackRequest: setCallbackRequest,
                getNumberBlockRequest: getNumberBlockRequest,
                setNumberCreateBlockRequest: setNumberCreateBlockRequest,
                setNumberDeleteBlockRequest: setNumberDeleteBlockRequest,
                getNumberUnblockRequest: getNumberUnblockRequest,
                setNumberCreateUnblockRequest: setNumberCreateUnblockRequest,
                setContactEmailRequest: setContactEmailRequest,
                getReportsPayment: getReportsPayment,
                getReportsHistory: getReportsHistory,
                getReportsExpense: getReportsExpense,
                setReportsCreateRequest: setReportsCreateRequest,
                getReportStatus: getReportStatus,
                getCountersRequest: getCountersRequest,
                getCountersUpdateRequest: getCountersUpdateRequest,
                getPromo: getPromo
            };
            return getMethods;

            function getProfile() {
                return defaultGET(apiUrl.profile, 'getProfile');
            }

            function setProfile(data) {
                return $http
                    .put(apiUrl.profile, data)
                    .catch(error);

                function error(message) {
                    if (_.get(message, 'status') === 400 ||
                        _.get(message, 'status') === 409) {
                        return message;
                    }
                    exception.catcher('XHR Failed setProfile.')(message);
                    return false;
                }
            }

            function getPhone() {
                return defaultGET(apiUrl.profilePhone, 'getPhone');
            }

            function getBalance() {
                return defaultGET(apiUrl.profileBalance, 'getBalance');
            }

            function getTariffs(options) {
                return defaultGET(apiUrl.tariffs, 'getTariffs', options);
            }

            function getTariffCurrent() {
                return defaultGET(apiUrl.tariffCurrent, 'getTariffCurrent');
            }

            function getTariffRequest() {
                return defaultGET(apiUrl.tariffChangeRequest, 'getTariffRequest');
            }

            function setTariffCreateRequest(url) {
                return $http
                    .post(apiUrl.tariffChangeRequest, {tarif: url})
                    .then(success);

                function success(response) {
                    return response.data;
                }

            }

            function setTariffDeleteRequest() {
                return $http
                    .delete(apiUrl.tariffChangeRequest)
                    .then(success);

                function success(response) {
                    return response.data;
                }
            }

            function getServices() {
                return defaultGET(apiUrl.services, 'getServices');
            }

            function getServiceConnected() {
                return defaultGET(apiUrl.serviceConnected, 'getServiceConnected');
            }

            function getServiceRequest() {
                return defaultGET(apiUrl.serviceChangeRequest, 'getServiceRequest');
            }

            function setServiceCreateRequest(serviceUrl, type) {
                return $http
                    .post(apiUrl.serviceChangeRequest, {
                        service: serviceUrl,
                        request_type: type  //jshint ignore:line
                    })
                    .then(success);

                function success(response) {
                    return response.data;
                }
            }

            function setServiceDeleteRequest(requestUrl) {
                return $http
                    .delete(requestUrl)
                    .then(success);

                function success(response) {
                    return response.data;
                }
            }

            function setCallbackRequest(phone) {
                return $http
                    .post(apiUrl.callbackRequest, {phone: phone});
            }

            function getNumberBlockRequest() {
                return defaultGET(apiUrl.numberBlockRequest, 'getNumberBlockRequest');
            }

            function setNumberCreateBlockRequest(date, endDate) {
                return $http
                    .post(apiUrl.numberBlockRequest, {expected_date: date, end_date: endDate})//jshint ignore:line
                    .then(success);

                function success(response) {
                    return response.data;
                }
            }

            function setNumberDeleteBlockRequest() {
                return $http
                    .delete(apiUrl.numberBlockRequest)
                    .then(success);

                function success(response) {
                    return response.data;
                }
            }

            function getNumberUnblockRequest() {
                return defaultGET(apiUrl.numberUnblockRequest, 'getNumberUnblockRequest');
            }

            function setNumberCreateUnblockRequest() {
                return $http
                    .post(apiUrl.numberUnblockRequest, {});
            }

            function setContactEmailRequest(data) {
                return $http
                    .post(apiUrl.contactEmailRequest, data);
            }

            function getReportsPayment(params, url) {
                url = url || apiUrl.reportsPayment;
                return defaultGET(url, 'getReportsPayment', {params: params});
            }

            function getReportsHistory(params, url) {
                url = url || apiUrl.reportsHistory;
                return defaultGET(url, 'getReportsHistory', {params: params});
            }

            function getReportsExpense(params, url) {
                url = url || apiUrl.reportsExpense;
                return defaultGET(url, 'getReportsExpense', {params: params});
            }

            function setReportsCreateRequest(request) {
                return $http
                    .post(apiUrl.reportsCreateRequest, request);
            }

            function getReportStatus(token, interval) {
                interval = interval || 3000;

                var deferred = $q.defer();

                var stop = $interval(request, interval);

                return deferred.promise;

                function request() {
                    return $http
                        .get(apiUrl.reportsStatus + token + '/')
                        .then(success);

                    function success(response) {
                        if (response.status === 200) {
                            $interval.cancel(stop);
                            return deferred.resolve({token: token, status: true});
                        }
                    }
                }
            }

            function getCountersRequest() {
                return defaultGET(apiUrl.counters, 'getCountersRequest');
            }

            function getCountersUpdateRequest(token) {
                var url = apiUrl.countersUpdate;
                if (token) {
                    url += token + '/';
                }

                return defaultGET(url, 'getCountersUpdateRequest');
            }

            function getPromo() {
                return defaultGET(apiUrl.promo, 'getPromo');
            }

            function defaultGET(url, funcName, options) {
                options = options || {};
                funcName = funcName || '';

                return $http
                    .get(url, options)
                    .then(success)
                    .catch(error);

                function success(response) {
                    return response.data;
                }

                function error(message) {
                    if (message.status !== 440) {
                        exception.catcher('XHR Failed' + (funcName ? ' for ' + funcName : '') + '.')(message);
                    }
                    var deferred = $q.defer();
                    deferred.reject(message);
                    return deferred.promise;
                }
            }
        }
    }
})();
