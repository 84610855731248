import toastr from "toastr";
import _ from "lodash";
import moment from "moment";
import Hammer from "hammerjs";

(function() {
    'use strict';

    const lkConfig = window.lkConfig || {};

    angular
        .module('lk.core')
        .constant('toastr', toastr)
        .constant('_', _)
        .constant('moment', moment)
        .constant('location_constant', lkConfig.address || "")
        .constant('contactPhone', lkConfig.contactPhone || "")
        .constant('gesture', {
            hammer: Hammer
        });
})();
