(function () {
    'use strict';

    angular
        .module('lk.core')
        .factory('PhonePrepService', PhonePrepService);

    function PhonePrepService(dataService) {
        return dataService
            .getPhone()
            .catch(error);

        function error() {
            return {
                phone: '',
                is_active: false // jshint ignore:line
            };
        }
    }
})();
