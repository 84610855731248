(function () {
    'use strict';

    angular
        .module('core.feature')
        .directive('feature', feature);

    function feature(FeatureService) {
        var directive = {
            restrict: 'AE',
            scope: {
                feature: '@'
            },
            link: link
        };
        return directive;

        function link(scope, element) {
            if (FeatureService.isFeatureAvailable(scope.feature)) {
                element.removeClass('ng-hide ng-hide-important');
            } else {
                element.addClass('ng-hide ng-hide-important');
            }
        }
    }
})();
