(function () {
    'use strict';

    angular
        .module('lk.main')
        .factory('ReportsModal', ReportsModal);

    function ReportsModal(ngModal) {
        return ngModal({
            controller: 'ReportsModalController as modal',
            container: document.getElementById('popups'),
            template: require("./template.html").default
        });
    }
})();
