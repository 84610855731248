(function () {
    'use strict';

    angular
        .module('lk.core')
        .filter('m2m', filter);

    function filter(_) {
        var notConform = function (value, filter, field) {

            if (_.isEmpty(filter)) {
                return false;
            }

            if (!value[field] || !value[field].length) {
                return true;
            }

            if (_.isString(value[field]) && value[field] !== filter.toString()) {
                return true;
            }
            if (_.isArray(value[field])) {
                if (_.isString(filter) && _.difference([filter], value[field]).length) {
                    return true;
                }
                if (_.isArray(filter) && _.difference(filter, value[field]).length) {
                    return true;
                }
            }
            return false;
        };
        return function (values, filters) {
            return _.filter(values, function (value) {
                return !_.find(filters, function (filter, field) {
                    return notConform(value, filter, field);
                });
            });
        };
    }
})();
