(function() {
    'use strict';

    angular
        .module('core.exception')
        .factory('extendExceptionHandler', extendExceptionHandler)
        .config(config);

    function config($provide) {
        $provide.decorator('$exceptionHandler', extendExceptionHandler);
    }

    function extendExceptionHandler($delegate) {
        return function(exception, cause) {
            $delegate(exception, cause);
            // Перепробрасываем ошибку, потому что angular не всегда применяет source map к ее стеку.
            // https://github.com/angular/angular.js/issues/5217#issuecomment-70064960
            setTimeout(function() {
                throw exception;
            });
        };
    }
})();
