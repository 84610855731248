(function (angular) {
    'use strict';

    angular
        .module('lk.auth')
        .config(route);

    function route($stateProvider) {
        var state = {
            name: 'auth.changepass',
            url: '/changepass',
            template: require("./changepass.html").default,
            controller: 'ChangePassController as cpVm',
            resolve: {
                profile: 'ProfilePrepService'
            }
        };

        $stateProvider
            .state(state);
    }
})(window.angular);
