(function () {
    'use strict';

    angular
        .module('lk.main')
        .factory('ServiceRemoveConnectModal', ServiceRemoveConnectModal);

    function ServiceRemoveConnectModal(ngModal) {
        return ngModal({
            controller: 'ServiceRemoveConnectModalController as modal',
            container: document.getElementById('popups'),
            template: require("../templates/remove-connect-modal.html").default
        });
    }
})();
