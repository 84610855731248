(function () {
    'use strict';

    angular
        .module('core.feature')
        .factory('FeaturePrepService', FeaturePrepService);

    function FeaturePrepService(dataFeatureService, FeatureService) {
        return dataFeatureService
            .getFeatures()
            .then(success);

        function success(features) {
            return FeatureService.setFeatures(features);
        }
    }
})();
