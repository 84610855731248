import * as Sentry from "@sentry/browser";
import { Angular as AngularIntegration } from "@sentry/integrations";

(function () {
    'use strict';

    var sentryDsn = window.sentry_dsn;
    if (!sentryDsn) {
        console.log("Sentry is not initialized since DSN is not set");
        angular.module('core.sentry', []);
        return;
    }

    var sentryRelease = window.sentry_release;
    var sentryEnvironment = window.sentry_environment;

    try {
        Sentry.init({
            dsn: sentryDsn,
            release: sentryRelease,
            environment: sentryEnvironment,
            integrations: [new AngularIntegration()],
        });
    } catch (e) {
        console.error(e);
        angular.module('core.sentry', []);
        return;
    }

    angular.module('core.sentry', ['ngSentry']);
})();
