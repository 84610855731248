(function () {
    'use strict';

    angular
        .module('lk.auth')
        .controller('GetPassController', controller);

    function controller($scope, auth) {
        /* jshint validthis: true */
        var gpVm = this;

        gpVm.send = send;
        gpVm.formValues = {};

        return gpVm;

        function send(data) {
            if (gpVm.block) {
                return false;
            }
            gpVm.block = true;
            auth
                .getPassword(data)
                .then(success)
                .catch(error);

            function success() {
                gpVm.block = false;
                gpVm.success = true;
            }

            function error(response) {
                gpVm.block = false;

                if (response.data.phone === 'temporarilyImpossibleAuth') {
                    gpVm.form.phone.$error = {
                        temporarilyImpossibleAuth: true
                    };
                } else {
                    gpVm.form.phone.$error = {
                        phone: true
                    };
                }

                gpVm.form.$valid = false;
                $scope.phoneWatch = $scope.$watch('gpVm.formValues.phone', watch);

                function watch(oldVal, newVal) {
                    if (oldVal === newVal) {
                        return false;
                    }
                    gpVm.form.phone.$error = {
                        temporarilyImpossibleAuth: false,
                        phone: false
                    };
                    $scope.phoneWatch();
                }
            }
        }
    }
})();
