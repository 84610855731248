(function () {
    'use strict';

    angular
        .module('lk.main')
        .controller('ReportsModalController', controller);

    function controller($scope, $rootScope, ReportsModal, dataService, moment, _) {
        /*jshint validthis: true */
        var modal = this;

        modal.close = closeModal;
        modal.send = send;
        modal.block = false;
        modal.error = false;

        modal.format = 'pdf';
        modal.method = 'download';

        modal.sendEmail = '';
        modal.editEmail = false;

        $rootScope.isModalActive = true;

        activate();

        return modal;

        function activate() {
            getProfile();
        }

        function getProfile() {
            modal.block = true;
            return dataService
                .getProfile()
                .then(success);

            function success(profile) {
                modal.block = false;
                if (profile) {
                    modal.sendEmail = profile.email || '';
                    modal.editEmail = profile.email ? false : true;
                }
            }
        }

        function send() {

            var request = {
                /* jshint ignore:start */
                send: modal.method === 'send',
                email: modal.sendEmail,
                detail_types: $scope.selected || [],
                report_type: modal.format
                /* jshint ignore:end */
            };

            /* jshint ignore:start */
            if ($scope.dateRange[$scope.selectDateRange]) {
                request.start_date = moment($scope.dateRange[$scope.selectDateRange].from).format('YYYY-MM-DD');
                request.end_date = moment().format('YYYY-MM-DD');
            } else if ($scope.period.from) {
                request.start_date = moment($scope.period.from).format('YYYY-MM-DD');
                request.end_date = moment($scope.period.unit).format('YYYY-MM-DD');
            } else {
                return false;
            }
            /* jshint ignore:end */

            modal.block = true;

            return dataService
                .setReportsCreateRequest(request)
                .then(success)
                .catch(error);

            function success(response) {
                if (response.status === 201) {
                    return dataService
                        .getReportStatus(response.data.token)
                        .then(getStatus);
                }
                modal.block = false;
                modal.sended = true;

                return true;

                function getStatus(data) {
                    modal.block = false;
                    modal.link = data.token;
                }
            }

            function error(response) {
                modal.block = false;

                if (_.get(response, 'data.email')) {
                    modal.editEmail = true;
                    modal.formEmail.$valid = false;
                    modal.formEmail.email.$setDirty();
                    modal.formEmail.email.$setValidity('email', false);
                    return false;
                }

                modal.error = true;
            }
        }

        function closeModal() {
            if (modal.block) {
                return false;
            }
            return ReportsModal
                .deactivate()
                .then(success);

            function success() {
                modal.block = false;
                $rootScope.isModalActive = false;
            }
        }
    }
})();
