(function () {
    'use strict';

    angular
        .module('core.authenticate')
        .run(check);

    function check($rootScope, $state, auth, authVar) {
        $rootScope.$on('$stateChangeStart', $stateChangeStart);

        function $stateChangeStart(event, toState, toParams) {
            if (auth.getChangePasswordToken() && authVar.changePasswordState !== toState.name) {
                event.preventDefault();
                $state.go(authVar.changePasswordState, {
                    next: $state.href(toState, toParams)
                });
            }
            if (toState.authenticate && !auth.isAuthenticated()) {
                event.preventDefault();
                $state.go('auth.login', {
                    next: $state.href(toState, toParams)
                });
            }
        }
    }
}());
