(function (angular) {
    'use strict';

    angular
        .module('lk.main')
        .config(route);

    function route($stateProvider) {
        var state = {
            name: 'lk.index',
            url: '',
            template: require("./index.html").default,
            controller: 'IndexController as indexVm',
            authenticate: true
        };

        $stateProvider
            .state(state);
    }
})(window.angular);
