(function () {
    'use strict';

    angular
        .module('lk.main')
        .factory('ServiceConnectModal', ServiceConnectModal);

    function ServiceConnectModal(ngModal) {
        return ngModal({
            controller: 'ServiceConnectModalController as modal',
            container: document.getElementById('popups'),
            template: require("../templates/connect-modal.html").default
        });
    }
})();
