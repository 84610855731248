(function () {
    'use strict';

    angular
        .module('lk.app')
        .directive('chosen', chosen);

    function chosen() {
        var directive = {
            require: 'ngModel',
            link: link
        };
        return directive;

        function link(scope, elem, attr, ngModel) {
            $(elem)
                .chosen({disable_search_threshold: 10}) //jshint ignore:line
                .change(change);

            ngModel.$formatters.push(formatter);

            return ngModel;

            function formatter(value) {
                $(elem).val(value).trigger('chosen:updated');
                return value;
            }

            function change(event, value) {
                ngModel.$setViewValue(value.selected);
            }

        }
    }
})();
