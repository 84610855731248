(function () {
    'use strict';

    angular
        .module('lk.auth')
        .controller('RecoveryController', controller);

    function controller(auth, $stateParams, $state) {
        /* jshint validthis: true */
        var recoveryVm = this;

        activate();

        return recoveryVm;

        function activate() {
            recoveryVm.block = true;
            auth
                .recoveryCode($stateParams.code)
                .then(success)
                .catch(error);

            function success() {
                $state.go('auth.changepass');
            }

            function error() {
                recoveryVm.block = false;
                recoveryVm.error = true;
            }
        }
    }
})();
