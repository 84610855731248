(function () {
    'use strict';

    angular
        .module('lk.main')
        .controller('RemoveBlockNumberController', controller);

    function controller($rootScope, RemoveBlockNumberModal, dataService) {
        /*jshint validthis: true */
        var modal = this;

        modal.close = closeWindow;
        modal.remove = remove;
        modal.numberUnblock = numberUnblock;
        modal.block = false;
        modal.error = false;
        modal.unblock = $rootScope.phone ? $rootScope.phone.unblock : false;

        $rootScope.isModalActive = true;

        return modal;

        function remove() {
            modal.block = true;
            return dataService
                .setNumberDeleteBlockRequest()
                .then(success)
                .catch(error);

            function success() {
                modal.block = false;
                $rootScope.$broadcast('updateNumberBlockRequest');
                modal.close();
            }

            function error() {
                $rootScope.$broadcast('updateNumberBlockRequest');
                modal.block = false;
                modal.error = true;
            }
        }

        function numberUnblock() {
            modal.block = true;
            return dataService
                .setNumberCreateUnblockRequest()
                .then(success)
                .catch(error);

            function success() {
                modal.block = false;
                $rootScope.$broadcast('updateNumberUnblockRequest');
                $rootScope.$broadcast('updateNumberBlockRequest');
                modal.close();
            }

            function error() {
                $rootScope.$broadcast('updateNumberUnblockRequest');
                $rootScope.$broadcast('updateNumberBlockRequest');
                modal.block = false;
                modal.error = true;
            }
        }

        function closeWindow() {
            if (modal.block) {
                return false;
            }
            return RemoveBlockNumberModal
                .deactivate()
                .then(success);

            function success() {
                modal.block = false;
                $rootScope.isModalActive = false;
            }
        }
    }
})();
