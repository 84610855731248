(function () {
    'use strict';

    angular
        .module('core.feature')
        .factory('FeatureService', FeatureService);

    function FeatureService(_) {
        var config = {};

        var service = {
            setFeatures: setFeatures,
            isLkPage: isLkPage,
            isPageAvailable: isPageAvailable,
            isFeatureAvailable: isFeatureAvailable,
            getFeatureName: getFeatureName,
            getFeatureNameFromState: getFeatureNameFromState
        };

        return service;

        function setFeatures(features) {
            var feature;

            config = _.reduce(features, reduce, {});

            return config;

            function reduce(result, item) {
                feature = getFeatureName(item);

                if (_.isArray(result[feature.page])) {
                    result[feature.page].push(feature.name);
                } else {
                    result[feature.page] = [feature.name];
                }

                return result;
            }
        }

        function getFeatureName(feature) {
            var split = feature.split('__');
            if (split.length > 1) {
                return {
                    page: split[0],
                    name: split[1]
                };
            } else {
                return {
                    page: feature,
                    name: false
                };
            }
        }

        function isLkPage(state) {
            var split = state.split('.');
            return split[0] === 'lk';
        }

        function isPageAvailable(state) {
            var name = getFeatureNameFromState(state);
            if (config[name]) {
                return config[name].length > 0;
            }
            return false;
        }

        function isFeatureAvailable(featureStr) {
            var feature = getFeatureName(featureStr);

            if (!feature.name && feature.page) {
                if (config[feature.page]) {
                    return config[feature.page].length > 0;
                }
            }

            if (config[feature.page]) {
                return config[feature.page].indexOf(feature.name) > -1;
            }
            return false;
        }

        function getFeatureNameFromState(state) {
            var split = state.split('.');

            if (split.length > 1) {
                return split[1];
            }
            return state;
        }

    }
})();
