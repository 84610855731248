(function () {
    'use strict';

    angular
        .module('lk.core')
        .directive('strongPassword', strong);

    function strong() {
        var directive = {
            require: 'ngModel',
            link: link
        };
        return directive;

        function link(scope, elem, attr, ngModel) {

            ngModel.$parsers.unshift(function (value) {
                var valid = value ? getPasswordStrength(value) > 2 : true;
                ngModel.$setValidity('strong', valid);
                return valid ? value : undefined;
            });

            ngModel.$formatters.unshift(function (value) {
                ngModel.$setValidity('strong', value ? getPasswordStrength(value) > 2 : true);
                return value;
            });

        }

        function getPasswordStrength(password) {
            var score = 0;

            if (password.length < 6) {
                return 0;
            } else {
                score++;
            }

            if ((password.match(/[a-z]/)) &&
                (password.match(/[A-Z]/))) {
                score++;
            }

            if (password.match(/\d+/)) {
                score++;
            }

            if (password.match(/[^a-z\d]+/)) {
                score++;
            }

            if (password.length > 12) {
                score++;
            }

            return score;
        }
    }
})();
