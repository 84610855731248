(function () {
    'use strict';

    angular
        .module('lk.core')
        .filter('inContains', inContains);

    function inContains(_) {
        return function (values, filters) {
            return _.filter(values, function (value) {
                return _.find(filters, function (filter, field) {
                    if (!value[field]) {
                        return true;
                    }
                    if (!_.isArray(value[field]) || !_.isArray(filter)) {
                        return false;
                    }
                    if (!filter.length) {
                        return true;
                    }
                    return _.intersection(value[field], filter).length;
                });
            });
        };
    }
})();
