(function () {
    'use strict';

    angular
        .module('lk.core')
        .filter('phone', filter);

    function filter(_) {
        return function (value) {
            if (value && value.length === 10) {
                return '+7 ' + value.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, '$1 $2-$3-$4');
            }

        };
    }
})();
