(function () {
    'use strict';

    angular
        .module('lk.main')
        .controller('HeaderController', controller);

    function controller($auth, $rootScope, $windowLocation, dataService, phone, moment, $state,
                        BlockNumberModal, RemoveBlockNumberModal, FeatureService) {
        /*jshint validthis: true */
        var headerVm = this;

        headerVm.phone = phone;

        headerVm.balance = {
            update: updateBalance,
            loading: false,
            data: {}
        };

        headerVm.dateRange = $state.params && $state.params.period ? $state.params.period : 'day';

        headerVm.changeDateRange = function () {
            $state.go('lk.reports', {period: headerVm.dateRange});
        };

        headerVm.block = BlockNumberModal.activate;
        headerVm.removeBlock = RemoveBlockNumberModal.activate;

        headerVm.logout = logout;

        headerVm.showMenu = showMenu;

        headerVm.prevent = prevent;

        headerVm.promo = {};

        $rootScope.phone = phone;
        $rootScope.openMenu = false;

        active();

        return headerVm;

        function active() {
            updateBalance();
            updateUnblockRequest();
            updateBlockRequest();
            updatePromo();

            $rootScope.$on('updateNumberBlockRequest', updateBlockRequest);
            $rootScope.$on('updateNumberUnblockRequest', updateUnblockRequest);
            $rootScope.$watch('openMenu', function () {
                if ($rootScope.openMenu) {
                    document.addEventListener('touchmove', prevent, false);
                } else {
                    document.removeEventListener('touchmove', prevent, false);
                }
            });
        }

        function prevent(e) {
            var top = window.pageYOffset ? window.pageYOffset : document.body.scrollTop;
            if (top !== 0) {
                e.preventDefault();
                return false;
            }

            $('.header__holder').focus();

            var item = $(e.targetTouches[0].target);
            if (item.parents('.header__holder').length > 0) {
                $('.header__holder').focus();
                return true;
            }
            e.preventDefault();
        }

        function logout() {
            $auth.logout();
            $windowLocation.reload();
        }

        function updateBalance() {
            headerVm.balance.loading = true;
            return dataService
                .getBalance()
                .then(success);

            function success(data) {
                headerVm.balance.data = data;
                headerVm.balance.loading = false;
                headerVm.balance.updateAt = moment();
            }
        }

        function updatePromo() {
            return dataService
                .getPromo()
                .then(success);

            function success(data) {
                headerVm.promo.data = data;
            }
        }

        function updateBlockRequest() {
            if (!FeatureService.isFeatureAvailable('all__number_block')) {
                return false;
            }
            headerVm.blockRequestloading = true;
            return dataService
                .getNumberBlockRequest()
                .then(success)
                .catch(error);

            function success(data) {
                headerVm.blockRequestloading = false;
                headerVm.blockRequest = data;
                $rootScope.phone.unblock = data['can_unblock'] || false;
            }

            function error() {
                headerVm.blockRequestloading = false;
            }
        }

        function updateUnblockRequest() {
            if (!FeatureService.isFeatureAvailable('all__number_block')) {
                return false;
            }
            headerVm.unblockRequestloading = true;
            return dataService
                .getNumberUnblockRequest()
                .then(success)
                .catch(error);

            function success(data) {
                headerVm.UnblockRequest = data.hasOwnProperty('block_type');
                headerVm.unblockRequestloading = false;
            }

            function error() {
                headerVm.unblockRequestloading = false;
            }
        }

        function showMenu() {
            $rootScope.openMenu = !$rootScope.openMenu;
        }
    }
})();
