(function () {
    'use strict';

    angular
        .module('lk.core')
        .factory('internalServerErrorInterceptor', expireTokenInterceptor);

    function expireTokenInterceptor($q, $windowLocation) {
        return {
            responseError: function (rejection) {
                if (rejection.status >= 500) {
                    $windowLocation.replace('/500/');
                    return $q.reject(rejection);
                }
                return $q.reject(rejection);
            }
        };
    }
})();
