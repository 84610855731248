(function () {
    'use strict';

    angular
        .module('lk.main')
        .factory('TariffHelper', helper);

    function helper(_) {
        var service = {
            setUnCheckedValue: setUnCheckedValue,
            getConvertedTagsTariffs: getConvertedTagsTariffs,
            getSelectedSchema: getSelectedSchema,
            isValueContain: isValueContain,
            isCleanFilters: isCleanFilters
        };
        return service;

        function getConvertedTagsTariffs(tariffs) {
            return _.map(tariffs, map);

            function map(item) {
                if (!_.get(item, 'tags')) {
                    return item;
                }
                item.tags = _.map(_.get(item, 'tags'), _.escape);
                return item;
            }
        }

        function getSelectedSchema(filters) {
            return _.chain(filters)
                .filter({type: 'checkbox'})
                .transform(transform, {})
                .value();

            function transform(result, item) {
                result[item.field] = [];
            }
        }

        function isValueContain(selected, field, value) {
            return selected[field] === value ||
                (_.isArray(selected[field]) && _.includes(selected[field], value));
        }

        function isCleanFilters(selected) {
            var clean = true;

            _.each(selected, each);

            return clean;

            function each(item) {
                if (_.isArray(item)) {
                    clean = !item.length;
                } else if (item) {
                    clean = false;
                }
            }
        }

        function setUnCheckedValue($event, items, field, value) {
            if (items[field] === value) {
                $event.preventDefault();
                items[field] = '';
            }
        }
    }
})();
