(function (angular) {
    'use strict';

    angular
        .module('lk.auth')
        .config(route)
        .run(['$templateCache', cacheIncludableTemplates]);

    function route($stateProvider) {
        var state = {
            name: 'auth.login',
            url: '/login',
            template: require("./templates/login.html").default,
            controller: 'LoginController as loginVm'
        };

        $stateProvider
            .state(state);
    }

    function cacheIncludableTemplates($templateCache) {
        $templateCache.put('auth/login/templates/form.html', require("./templates/form.html").default);
    }
})(window.angular);
