(function (angular) {
    'use strict';

    angular
        .module('lk.auth')
        .config(route);

    function route($stateProvider) {
        var state = {
            name: 'auth.relogin',
            url: '/relogin',
            template: require("./relogin.html").default,
            controller: 'LoginController as loginVm'
        };

        $stateProvider
            .state(state);
    }
})(window.angular);
