(function () {
    'use strict';

    angular
        .module('lk.app')
        .controller('PageNotFountController', PageNotFountController);

    function PageNotFountController($rootScope) {
        /* jshint validthis: true */
        var vm = this;

        vm.url = $rootScope.pageNotFoundUrl;

        return vm;
    }

})();
