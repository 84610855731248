(function (angular) {
    'use strict';

    angular
        .module('lk.auth')
        .config(route);

    function route($stateProvider) {
        var state = {
            name: 'auth.getpass',
            url: '/getpass',
            template: require("./getpass.html").default,
            controller: 'GetPassController as gpVm'
        };

        $stateProvider
            .state(state);
    }
})(window.angular);
