(function () {
    'use strict';

    angular
        .module('lk.auth')
        .controller('ChangePassController', controller);

    function controller($stateParams, $location, auth, authVar, profile, _) {
        /* jshint validthis: true */
        var cpVm = this;

        if (profile) {
            cpVm.formValues = {
                'notify_method': profile.notify_method || 1, //jshint ignore:line
                'email': profile.email || ''
            };
        } else {
            cpVm.error = true;
        }

        cpVm.send = send;

        return cpVm;

        function send(data) {
            if (cpVm.block) {
                return false;
            }
            cpVm.block = true;
            auth
                .changePassword(data)
                .then(success)
                .catch(error);

            function success() {
                var next = $stateParams.next ? $stateParams.next : authVar.nextUrl;
                return $location.path(next);
            }

            function error(response) {
                cpVm.block = false;

                if (_.get(response, 'status') !== 200 &&
                    _.get(response, 'data.email')) {
                    cpVm.formValues.notify_method = 2; // jshint ignore:line
                    cpVm.form.$valid = false;
                    cpVm.form.email.$setDirty();
                    cpVm.form.email.$error = {
                        email: true
                    };
                    return false;
                }

                auth.setChangePasswordEmptyToken();
                cpVm.error = true;

                return false;
            }
        }
    }
})();
