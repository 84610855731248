(function () {
    'use strict';

    angular
        .module('lk.main')
        .factory('ServiceDisconnectModal', ServiceDisconnectModal);

    function ServiceDisconnectModal(ngModal) {
        return ngModal({
            controller: 'ServiceDisconnectModalController as modal',
            container: document.getElementById('popups'),
            template: require("../templates/disconnect-modal.html").default
        });
    }
})();
