(function () {
    'use strict';

    angular
        .module('lk.main')
        .controller('IndexController', controller);

    function controller($rootScope, $filter, dataService, FeatureService,
                        LkHelper, ServiceHelper, moment) {
        /* jshint validthis: true */
        var indexVm = this;
        var filter = $filter('filter');

        indexVm.updateServices = updateServices;

        $rootScope.$on('updateServices', updateServices);

        activate();

        return indexVm;

        function activate() {
            updateServices();
        }

        function updateServices() {
            if (!FeatureService.isFeatureAvailable('index__services')) {
                return false;
            }
            indexVm.loading = true;
            return dataService
                .getServices()
                .then(successServices)
                .then(successConnected)
                .then(successRequest);

            function successServices(services) {
                indexVm.services = LkHelper.getConvertedTags(services);
                return dataService.getServiceConnected();
            }

            function successConnected(connected) {
                indexVm.services = ServiceHelper.getConnectedServices(
                    indexVm.services,
                    connected
                );
                return dataService.getServiceRequest();
            }

            function successRequest(request) {
                indexVm.services = ServiceHelper.getRequestServices(
                    indexVm.services,
                    request
                );
                indexVm.connectServices = filter(indexVm.services, {connect: {status: true}});
                indexVm.disconnectServices = filter(indexVm.services, {connect: {status: false}});
                indexVm.loading = false;
                indexVm.updateAt = moment();
                return indexVm.services;
            }
        }

    }
})();
