(function () {
    'use strict';

    angular
        .module('lk.app')
        .controller('InternalServerErrorController', InternalServerErrorController);

    function InternalServerErrorController(dataService, _) {
        /* jshint validthis: true */
        var vm = this;

        vm.block = false;
        vm.complete = false;
        vm.error = false;
        vm.formValues = {};
        vm.send = send;

        return vm;

        function send(data) {
            if (vm.block) {
                return false;
            }

            vm.block = true;

            return dataService
                .setContactEmailRequest(data)
                .then(success)
                .catch(error);

            function success(response) {
                vm.block = false;
                vm.complete = true;
            }

            function error(response) {
                vm.block = false;
                if (_.get(response, 'data.email')) {
                    vm.form.$valid = false;
                    vm.form.email.$error = {
                        email: true
                    };
                } else {
                    vm.error = true;
                }
            }
        }
    }

})();
