(function () {
    'use strict';

    angular
        .module('lk.main')
        .factory('LkHelper', helper);

    function helper(_) {
        var service = {
            getConvertedTags: getConvertedTags
        };
        return service;

        function getConvertedTags(items) {
            return _.map(items, map);

            function map(item) {
                item.tags = _.map(item.tags, _.escape);
                return item;
            }
        }

    }
})();
