(function () {
    'use strict';

    angular
        .module('lk.main')
        .controller('ServiceRemoveConnectModalController', controller);

    function controller($scope, $rootScope, ServiceRemoveConnectModal, dataService, _) {
        /*jshint validthis: true */
        var modal = this;

        modal.close = closeWindow;
        modal.remove = remove;
        modal.block = false;
        modal.error = false;

        $rootScope.isModalActive = true;

        return modal;

        function remove() {
            var requestUrl = _.get($scope, 'service.request.url');
            modal.block = true;
            return dataService
                .setServiceDeleteRequest(requestUrl)
                .then(success)
                .catch(error);

            function success() {
                modal.block = false;
                $rootScope.$broadcast('updateServices');
                modal.close();
            }

            function error() {
                $rootScope.$broadcast('updateServices');
                modal.block = false;
                modal.error = true;
            }
        }

        function closeWindow() {
            if (modal.block) {
                return false;
            }
            return ServiceRemoveConnectModal
                .deactivate()
                .then(success);

            function success() {
                modal.block = false;
                $rootScope.isModalActive = false;
            }
        }
    }
})();
