(function () {
    'use strict';

    angular
        .module('core.feature')
        .run(check);

    function check($rootScope, $state, FeatureService) {
        $rootScope.$on('$stateChangeSuccess', stateChangeSuccess);

        function stateChangeSuccess(event, toState) {
            if (FeatureService.isLkPage(toState.name) && !FeatureService.isPageAvailable(toState.name)) {
                event.preventDefault();
                $state.transitionTo('lk.404');
            }
        }
    }
}());
