(function () {
    'use strict';

    angular
        .module('lk.core')
        .directive('deviceType', deviceType);

    function deviceType(deviceDetector, detectUtils) {
        var directive = {
            restrict: 'A',
            link: link
        };
        return directive;

        function link(scope, element) {
            element.addClass('os-' + deviceDetector.os);
            element.addClass('browser-' + deviceDetector.browser);
            element.addClass('device-' + deviceDetector.device);
            element.addClass(detectUtils.isMobile() ? 'mobileBrowser' : 'desktopBrowser');
        }
    }
})();
