(function () {
    'use strict';

    angular
        .module('lk.core')
        .config(html5mode)
        .config(toastrConfig)
        .config(datepickerConfig)
        .config(serverError)
        .run(momentConfig);

    function momentConfig(amMoment) {
        amMoment.changeLocale('ru');
    }

    function datepickerConfig($datepickerProvider) {
        angular.extend($datepickerProvider.defaults, {
            monthFormat: 'MMM',
            monthTitleFormat: 'MMM yyyy'
        });
    }

    function toastrConfig(toastr) {
        toastr.options.timeOut = 4000;
        toastr.options.positionClass = 'toast-bottom-right';
    }

    function html5mode($locationProvider) {
        $locationProvider.html5Mode({
            enabled: true,
            requireBase: false
        });
        $locationProvider.hashPrefix('!');
    }

    function serverError($httpProvider) {
        $httpProvider.interceptors.push('internalServerErrorInterceptor');
    }
})();
