(function () {
    'use strict';

    angular
        .module('lk.auth')
        .controller('ConfirmController', controller);

    function controller(auth, $stateParams, $state) {
        /* jshint validthis: true */
        var confirmVm = this;

        activate();

        return confirmVm;

        function activate() {
            confirmVm.block = true;
            auth
                .confirmEmail($stateParams.code)
                .then(success)
                .catch(error);

            function success() {
                $state.go('lk.profile');
            }

            function error() {
                confirmVm.block = false;
                confirmVm.error = true;
            }
        }
    }
})();
