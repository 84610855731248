(function () {
    'use strict';

    angular
        .module('lk.main')
        .controller('ServiceDisconnectModalController', controller);

    function controller($scope, $rootScope, ServiceDisconnectModal, dataService) {
        /*jshint validthis: true */
        var modal = this;

        modal.close = closeWindow;
        modal.disconnect = disconnect;
        modal.block = false;
        modal.error = false;

        var REQUEST_TYPE_DISCONNECT = 2;

        $rootScope.isModalActive = true;

        return modal;

        function disconnect() {
            modal.block = true;
            return dataService
                .setServiceCreateRequest($scope.service.url, REQUEST_TYPE_DISCONNECT)
                .then(success)
                .catch(error);

            function success() {
                modal.block = false;
                $rootScope.$broadcast('updateServices');
                modal.close();
            }

            function error() {
                modal.block = false;
                modal.error = true;
            }
        }

        function closeWindow() {
            if (modal.block) {
                return false;
            }
            return ServiceDisconnectModal
                .deactivate()
                .then(success);

            function success() {
                modal.block = false;
                $rootScope.isModalActive = false;
            }
        }
    }
})();
