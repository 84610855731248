(function () {
    'use strict';

    angular
        .module('lk.main')
        .factory('ServiceHelper', helper);

    function helper(_) {
        var service = {
            getConnectedServices: getConnectedServices,
            getRequestServices: getRequestServices
        };
        return service;

        function getConnectedServices(services, connected) {
            var connect;
            return _.map(services, map);

            function map(item) {
                connect = _.find(connected, {url: item.url});
                item.connect = {
                    status: false
                };
                if (connect) {
                    /* jshint camelcase: false */
                    item.connect = {
                        status: true,
                        date: connect.connect_date,
                        isPermanent: connect.is_permanent
                    };
                    /* jshint ignore:end */
                }
                return item;
            }
        }

        function getRequestServices(services, requests) {
            var request;
            return _.map(services, map);

            function map(item) {
                request = _.find(requests, {service: item.url});
                item.request = {
                    status: false
                };
                if (request) {
                    /* jshint camelcase: false */
                    item.request = {
                        status: true,
                        type: request.request_type,
                        inProgress: request.in_progress,
                        expectedDate: request.expected_date,
                        url: request.url
                    };
                    /* jshint ignore:end */
                }
                return item;
            }
        }

    }
})();
