(function () {
    'use strict';

    angular
        .module('lk.main')
        .controller('ServicesController', controller);

    function controller($rootScope, $scope, $filter, dataService, serviceFilters,
                        LkHelper, ServiceHelper, moment) {
        /* jshint validthis: true */
        var servicesVm = this;
        var filter = $filter('filter');

        servicesVm.filter = serviceFilters;
        servicesVm.selected = [];
        servicesVm.updateServices = updateServices;
        servicesVm.togglePanel = togglePanel;

        $rootScope.$on('updateServices', updateServices);

        activate();

        return servicesVm;

        function activate() {
            updateServices();
        }

        function updateServices() {
            servicesVm.loading = true;
            return dataService
                .getServices()
                .then(successServices)
                .then(successConnected)
                .then(successRequest);

            function successServices(services) {
                servicesVm.services = LkHelper.getConvertedTags(services);
                return dataService.getServiceConnected();
            }

            function successConnected(connected) {
                servicesVm.services = ServiceHelper.getConnectedServices(
                    servicesVm.services,
                    connected
                );
                return dataService.getServiceRequest();
            }

            function successRequest(request) {
                servicesVm.services = ServiceHelper.getRequestServices(
                    servicesVm.services,
                    request
                );
                servicesVm.connectServices = filter(servicesVm.services, {connect: {status: true}});
                servicesVm.disconnectServices = filter(servicesVm.services, {connect: {status: false}});
                servicesVm.loading = false;
                servicesVm.updateAt = moment();
                return servicesVm.services;
            }
        }

        function togglePanel() {
            servicesVm.panel = servicesVm.panel ? false : true;
        }
    }
})();
