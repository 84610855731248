(function () {
    'use strict';

    angular
        .module('lk.main')
        .factory('TariffDisconnectModal', TariffDisconnectModal);

    function TariffDisconnectModal(ngModal) {
        return ngModal({
            controller: 'TariffDisconnectModalController as modal',
            container: document.getElementById('popups'),
            template: require('../templates/disconnect-modal.html').default
        });
    }
})();
