(function () {
    'use strict';

    angular
        .module('lk.main')
        .run(menu);

    function menu($rootScope) {

        var pageNames = {
            'lk.index': 'Главная страница',
            'lk.tariffs': 'Тарифы',
            'lk.reports': 'Фин. информация',
            'lk.services': 'Услуги',
            'lk.profile': 'Личные данные'
        };

        $rootScope.$on('$stateChangeStart', $stateChangeStart);

        function $stateChangeStart(event, toState) {
            $rootScope.openMenu = false;
            $rootScope.pageName = pageNames[toState.name] || '';
        }
    }

})();
