(function () {
    'use strict';

    angular
        .module('lk.main')
        .factory('reportsFilters', reportsFilters);

    function reportsFilters() {
        var filters = {
            'values': [
                {'name': 'Платежи', 'value': '1'},
                {'name': 'Местные звонки', 'value': '2'},
                {'name': 'SMS/MMS/WAP', 'value': '3'},
                {'name': 'Междугородние звонки', 'value': '4'},
                {'name': 'Международные звонки', 'value': '5'},
                {'name': 'GPRS/WLAN', 'value': '6'},
                {'name': 'Другое', 'value': '7'}
            ],
            'field': 'tags',
            'type': 'checkbox'
        };
        return filters;
    }
})();
