(function () {
    'use strict';

    angular
        .module('core.authenticate')
        .factory('authVar', authVar)
        .factory('auth', [
            '$window',
            '$state',
            'authVar',
            'satellizer.shared',
            'satellizer.config',
            'dataAuthService',
            auth
        ]);

    function authVar() {
        var config = {
            nextUrl: '/',
            changePasswordTokenName: 'change_password_token',
            changePasswordState: 'auth.changepass',
            changePasswordFlag: 'authenticated_via_temp_password'
        };
        return config;
    }

    function auth($window, $state, config, shared, sharedConfig, dataAuthService) {

        var service = {
            login: login,
            changePassword: changePassword,
            getPassword: getPassword,
            recoveryCode: recoveryCode,
            confirmEmail: confirmEmail,

            setToken: setToken,
            setChangePasswordEmptyToken: setChangePasswordEmptyToken,
            getChangePasswordToken: getChangePasswordToken,
            setChangePasswordToken: setChangePasswordToken,
            isAuthenticated: shared.isAuthenticated
        };

        return service;

        function login(data) {
            return dataAuthService
                .login(data)
                .then(success);

            function success(response) {
                return setToken(response);
            }
        }

        function confirmEmail(data) {
            return dataAuthService
                .confirmEmail(data)
                .then(success);

            function success(response) {
                return response;
            }
        }

        function changePassword(data) {
            return dataAuthService
                .changePassword(data)
                .then(success);

            function success(response) {
                setChangePasswordEmptyToken();
                shared.setToken(response);
                return response;
            }
        }

        function getPassword(data) {
            return dataAuthService
                .getPassword(data);
        }

        function recoveryCode(data) {
            return dataAuthService
                .recoveryCode(data)
                .then(success);

            function success(response) {
                shared.removeToken();
                setChangePasswordToken(response);
                return response;
            }
        }

        function setToken(response, isLinking) {
            var data = response.data || response;
            var flag = data ? data[config.changePasswordFlag] : undefined;
            if (flag) {
                shared.removeToken();
                setChangePasswordToken(response);
                return false;
            } else {
                shared.setToken(response, isLinking);
                return true;
            }
        }

        function getChangePasswordToken() {
            return $window.localStorage[config.changePasswordTokenName];
        }

        function setChangePasswordEmptyToken() {
            delete $window.localStorage[config.changePasswordTokenName];
        }

        function setChangePasswordToken(response, redirect) {
            var data = response.data || response;
            redirect = redirect === undefined ? true : !!redirect;
            var token = data[sharedConfig.tokenName] || undefined;

            if (!token) {
                throw new Error(
                    'Expecting a token named "' +
                    sharedConfig.tokenName +
                    '" but instead got: ' +
                    JSON.stringify(data));
            }

            $window.localStorage[config.changePasswordTokenName] = token;

            if (redirect) {
                return $state.go(config.changePasswordState);
            } else {
                return false;
            }
        }
    }
})();
