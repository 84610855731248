(function () {
    'use strict';

    angular
        .module('lk.main')
        .factory('reportsDataService', reportsDataService);

    function reportsDataService(dataService, moment, _) {
        var services = {
            payment: getMethods('getReportsPayment'),
            history: getMethods('getReportsHistory'),
            expense: getExpenseMethods('getReportsExpense')
        };

        return services;

        function getExpenseMethods(service) {
            var options = {
                params: {}
            };

            var methods = {
                get: get,
                period: {
                    set: setPeriod
                },
                options: function () {
                    return options;
                }
            };

            return methods;

            function get() {
                return dataService[service](options.params)
                    .then(success);
            }

            function success(data) {
                return data;
            }

            function setPeriod(from, unit) {
                unit = unit || false;

                delete options.params['date__lte'];
                delete options.params['date__gte'];

                options.params['date__gte'] = moment(from).format('YYYY-MM-DD');

                if (unit) {
                    options.params['date__lte'] = moment(unit).format('YYYY-MM-DD');
                }

                return true;
            }
        }

        function getMethods(service) {
            var options = {
                count: 0,
                page: 10,
                next: '',
                previous: '',
                params: {
                    ordering: []
                }
            };

            var methods = {
                get: get,
                next: next,
                hasNext: hasNext,
                order: {
                    status: getOrderStatus,
                    toggle: setToggleOrder
                },
                period: {
                    set: setPeriod
                },
                detailTypes: {
                    set: setDetailTypes
                },
                options: function () {
                    return options;
                }
            };

            return methods;

            function get() {
                var opt = angular.copy(options);

                opt.params = convertParams(opt.params);

                return dataService[service](opt.params)
                    .then(success);
            }

            function next() {
                if (options.next) {
                    return dataService[service]({}, options.next)
                        .then(success);
                }

                return false;
            }

            function hasNext() {
                return !!options.next;
            }

            function setToggleOrder(field) {
                var order = getOrderStatus(field);
                if (_.isNull(order)) {
                    options.params.ordering.unshift('-' + field);
                } else {
                    _.pull(options.params.ordering, field, '-' + field);
                    options.params.ordering.unshift(order ? field : '-' + field);
                }
            }

            function getOrderStatus(field) {
                var order = null;
                if (options.params.ordering.indexOf(field) >= 0) {
                    order = false;
                }
                if (options.params.ordering.indexOf('-' + field) >= 0) {
                    order = true;
                }
                return order;
            }

            function setPeriod(from, unit) {
                unit = unit || false;

                delete options.params['date__lte'];
                delete options.params['date__gte'];

                options.params['date__gte'] = moment(from).format('YYYY-MM-DD');

                if (unit) {
                    options.params['date__lte'] = moment(unit).format('YYYY-MM-DD');
                }

                return true;
            }

            function setDetailTypes(detailTypes) {
                var detailTypePayment = '1';
                options.params['detail_types'] = _.without(detailTypes, detailTypePayment).join(',');

                return true;
            }

            function convertParams(params) {
                return _.chain(params)
                    .mapValues(map)
                    .omit(_.isEmpty)
                    .value();

                function map(param) {
                    if (_.isArray(param)) {
                        param = param.join(',');
                    }
                    return param;
                }
            }

            function success(data) {
                options.next = data.next;
                options.previous = data.previous;
                options.count = data.count;
                return data.results;
            }
        }
    }

})();
