(function () {
    'use strict';

    angular
        .module('lk.app')
        .directive('historyTable', historyTable);

    function historyTable(_) {
        var directive = {
            link: link
        };

        return directive;

        function link(scope, element) {
            var side = {
                right: true,
                left: false
            };

            scope.historyTableVm = {
                column: 0,
                offset: 0,
                isLeftSide: isLeftSide,
                isRightSide: isRightSide,
                toRight: toRight,
                toLeft: toLeft,
                getOffset: getOffset,
                getMaxColCount: getMaxColCount,
                setColumnCount: setColumnCount,
                getMaxOffset: getMaxOffset
            };

            return scope;

            function isLeftSide() {
                return side.left;
            }

            function isRightSide() {
                return side.right;
            }

            function getMaxOffset(contentWidth, containerWidth) {
                var content = $(element).find('.table__container');
                var container = $(element);
                containerWidth = containerWidth || container.width();
                contentWidth = contentWidth || content.width();
                return contentWidth - containerWidth;
            }

            function getOffset(count, cols) {
                var maxOffset = scope.historyTableVm.getMaxOffset();
                cols = cols || $(element).find('.table__container .table__new-item._head');

                var offset = _.reduce(cols.slice(0, count), reduce, 0);
                return offset > maxOffset ? maxOffset : offset;

                function reduce(sum, item) {
                    return sum + (item.width ? item.width() : $(item).width() + 20);
                }
            }

            function getMaxColCount(cols) {
                var offset = scope.historyTableVm.getMaxOffset();
                cols = cols || $(element).find('.table__container .table__new-item._head');
                var count = 0;

                _.each(cols, function (item) {
                    offset -= (item.width ? item.width() : $(item).width() + 20);
                    count += 1;
                    if (offset <= 0) {
                        return false;
                    }
                });

                return count;
            }

            function setColumnCount(count) {
                scope.historyTableVm.column = count;
                scope.historyTableVm.offset = scope.historyTableVm.getOffset(count);
            }

            function toRight() {
                var maxCount = scope.historyTableVm.getMaxColCount();
                if ((scope.historyTableVm.column + 3) >= maxCount) {
                    setColumnCount(maxCount);
                    side.left = true;
                    side.right = false;
                } else {
                    setColumnCount(scope.historyTableVm.column + 3);
                    side.left = true;
                }
            }

            function toLeft() {
                if ((scope.historyTableVm.column - 3) <= 0) {
                    setColumnCount(0);
                    side.right = true;
                    side.left = false;
                } else {
                    setColumnCount(scope.historyTableVm.column - 3);
                    side.right = true;
                }
            }
        }
    }
})();
