(function () {
    'use strict';

    angular
        .module('lk.main')
        .factory('ServiceRemoveDisconnectModal', ServiceRemoveDisconnectModal);

    function ServiceRemoveDisconnectModal(ngModal) {
        return ngModal({
            controller: 'ServiceRemoveDisconnectModalController as modal',
            container: document.getElementById('popups'),
            template: require("../templates/remove-disconnect-modal.html").default
        });
    }
})();
