(function (angular) {
    'use strict';

    angular
        .module('lk.main')
        .config(route);

    function route($stateProvider) {

        var state = {
            name: 'lk',
            url: '/',
            abstract: true,
            resolve: {
                features: 'FeaturePrepService'
            },
            views: {
                header: {
                    controller: 'HeaderController as headerVm',
                    template: require("./templates/header.html").default,
                    resolve: {
                        phone: 'PhonePrepService'
                    }
                },
                content: {
                    template: require("./templates/content.html").default
                },
                footer: {
                    controller: 'FooterController as footerVm',
                    template: require("./templates/footer.html").default
                }
            }
        };

        var state404 = {
            name: 'lk.404',
            url: '404/',
            views: {
                'content@': {
                    template: require("./templates/page-not-found.html").default,
                    controller: 'PageNotFountController as vm'
                }
            }
        };

        var state500 = {
            name: '500',
            url: '/500/',
            views: {
                header: {
                    template: require("../../auth/base/templates/header.html").default
                },
                content: {
                    template: require("./templates/internal-server-error.html").default,
                    controller: 'InternalServerErrorController as vm'
                },
                footer: {
                    template: require("../../auth/base/templates/footer.html").default
                }
            }
        };

        $stateProvider
            .state(state)
            .state(state404)
            .state(state500);

        function error() {
            return {
                phone: ''
            };
        }
    }
})(window.angular);
