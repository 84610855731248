(function () {
    'use strict';

    angular
        .module('lk.main')
        .factory('TariffConnectModal', TariffConnectModal);

    function TariffConnectModal(ngModal) {
        return ngModal({
            controller: 'TariffConnectModalController as modal',
            container: document.getElementById('popups'),
            template: require('../templates/connect-modal.html').default
        });
    }
})();
