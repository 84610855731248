(function() {
    'use strict';

    angular.module('lk.app', [
        'lk.core',
        'lk.main',
        'lk.auth'
    ]);

})();
