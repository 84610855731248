(function () {
    'use strict';

    angular
        .module('lk.core')
        .factory('ProfilePrepService', ProfilePrepService);

    function ProfilePrepService(dataService) {
        return dataService.getProfile();
    }
})();
