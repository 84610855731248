(function () {
    'use strict';

    angular
        .module('lk.app')
        .directive('menuGesture', menuGesture);

    function menuGesture(gesture, $rootScope) {
        var directive = {
            link: link
        };
        return directive;

        function link(scope, element, attr) {
            var options = {
                dragLockToAxis: true,
                dragBlockHorizontal: true,
                preventDefault: true,
                userSelect: false
            };
            var gestureObj = gesture.hammer(element[0], options);

            return gestureObj
                .on('swipeleft', swipeleft)
                .on('swiperight', swiperight);

            function swipeleft() {
                $rootScope.openMenu = true;
                $rootScope.$apply();
            }

            function swiperight() {
                $rootScope.openMenu = false;
                $rootScope.$apply();
            }
        }
    }
})();
